import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './details/details.component';
import { ordersRouting } from './orders.routes';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { PopupModule } from '../../core/components/popup/popup.module';
import { TableModule } from 'src/app/core/components/table/table.module';

@NgModule({
    declarations: [OrdersComponent, OrderDetailsComponent],
    providers: [DatePipe, CurrencyPipe],
    imports: [ordersRouting, CommonModule, PopupModule, FormsModule, FormModule, TableModule],
})
export class OrdersModule {}
