import { Component, Input, OnInit } from '@angular/core';
import { item, RowItem } from '../../../models/item.model';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
    selector: 'sRowItem',
    templateUrl: './row-item.component.html',
    styleUrls: ['row-item.component.less'],
})
export class RowItemComponent implements OnInit {
    @Input() data: RowItem;

    constructor(public responsive: ResponsiveService) {}

    ngOnInit() {
        if (this.data.expandable === undefined) {
            return;
        }
        document.documentElement.style.setProperty('--open-height', this.data.expandable.openHeight + 'px');
        document.documentElement.style.setProperty(
            '--closed-height',
            this.data.expandable.closedHeight + 'px'
        );
    }

    toggle(event) {
        for (const element of event.composedPath()) {
            if (
                (element as HTMLElement).classList !== undefined &&
                (element as HTMLElement).classList.contains('action')
            ) {
                return;
            }
        }
        this.data.expandable.open = !this.data.expandable.open;
    }

    isExpanded() {
        return (
            this.data.expandable !== undefined &&
            this.data.expandable.open &&
            !this.responsive.isSize(this.data.expandable.breakpoint)
        );
    }

    isExpandable() {
        return this.data.expandable !== undefined && !this.responsive.isSize(this.data.expandable.breakpoint);
    }

    showTableData(item: item, index: number) {
        if (this.isExpanded()) {
            for (let i = 0; i < this.data.items.length; i++) {
                let item = this.data.items[i];
                if (item.show == undefined || item.show) {
                    return i === index;
                }
            }
            return false;
        }
        return (
            (item.breakpoint === undefined ||
                item.breakpoint.state === undefined ||
                item.breakpoint.state === 'both' ||
                (item.breakpoint.state === 'after'
                    ? this.responsive.isSize(item.breakpoint.breakpoint)
                    : !this.responsive.isSize(item.breakpoint.breakpoint))) &&
            (item.expansion === undefined ||
                item.expansion !== 'expandable' ||
                this.responsive.isSize(this.data.expandable.breakpoint)) &&
            (item.show === undefined || item.show)
        );
    }
}
