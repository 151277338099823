import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IspvisionService, VisionWs } from 'src/app/core/services/ispvision.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'order-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.less'],
})
export class OrderDetailsComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        public visionWs: IspvisionService,
        private router: Router,
        private sanitizer: DomSanitizer
    ) {}
    public orderId: number;
    public order: VisionWs.OrderResponse;
    public loaded: boolean = false;
    public table: SafeHtml;

    async ngOnInit(): Promise<void> {
        this.orderId = Number.parseInt(this.activatedRoute.snapshot.paramMap.get('orderId'));
        //check if this order is valid
        this.order = await this.visionWs.getOrder(this.orderId);
        if (this.order.error_code < 0) {
            this.router.navigate(['/orders']);
            return;
        }
        this.loaded = true;
        this.table = this.sanitizer.bypassSecurityTrustHtml(this.order.email_html);
    }
}
