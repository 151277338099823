import { Component, OnInit } from '@angular/core';
import { TableService } from 'src/app/core/services/table.service';
import { TableColumn } from 'src/app/core/models/table.model';
import { Breakpoint } from 'src/app/core/services/responsive.service';
import { RowItem } from 'src/app/core/models/item.model';

@Component({
    selector: 'downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.less'],
})
export class DownloadsComponent implements OnInit {
    constructor(public tableService: TableService) {}

    public downloads: { href: string; title: string }[] = [
        {
            href: '/api/media/files/Prijslijst-Markiezen.pdf',
            title: 'Prijslijst',
        },
        {
            href: '/api/media/files/MN-Brochure.pdf',
            title: 'Brochure',
        },
        {
            href: '/api/media/files/Onderhoudsinstructies.pdf',
            title: 'Onderhoudsinstructies',
        },
        {
            href: '/api/media/files/Montage-instructies-A4.pdf',
            title: 'Montage instructies',
        },
        {
            href: '/api/media/files/Consumentenfolder.pdf',
            title: 'Consumentenfolder',
        },
    ];

    ngOnInit(): void {
        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [Breakpoint.MD], row: [Breakpoint.MD] },
            },
            this.mapColumns.bind(this),
            this.mapDownloadsToTable.bind(this),
            this.downloads
        );
    }

    private mapColumns(): TableColumn[] {
        return [
            {
                item: { type: 'text', text: 'Titel' },
                sortOn: 'title',
                width: 75,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
            {
                item: { type: 'text', text: 'itemUser.actions' },
                position: 'center',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    state: 'after',
                },
            },
        ];
    }

    /**
     * Map invoices to table format
     *
     * @author    Arjan Koemans <arjan@safira.nl>
     */
    private mapDownloadsToTable(download: { title: string; href: string }): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                colspan: 2,
                gap: 10,
                closedHeight: 16.8,
                openHeight: 83.6,
            },
            items: [
                {
                    type: 'text',
                    text: download.title,
                    expansion: 'hidden',
                },
                {
                    type: 'group',
                    position: 'center',
                    expansion: 'expandable',
                    items: [
                        {
                            type: 'text',
                            icon: 'cloud-arrow-down',
                            iconHover: true,
                            iconBorder: true,
                            action: {
                                type: 'anchor',
                                route: download.href,
                                target: 'blank',
                                download: true,
                            },
                            title: 'global_edit',
                        },
                    ],
                },
            ],
        };
    }
}
