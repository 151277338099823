import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DownloadsComponent } from './downloads/downloads.component';
import { OrderDetailsComponent } from '../orders/details/details.component';

import { AuthGuardService } from '../../core/services/auth/auth-guard.service';

export const downloadsRoutes: Routes = [
    {
        path: 'downloads',
        component: DownloadsComponent,
    },
];

export const downloadsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(downloadsRoutes);
