import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { DownloadsComponent } from './downloads/downloads.component';
import { downloadsRouting } from './downloads.routes';
import { FormsModule } from '@angular/forms';
import { FormModule } from 'src/app/core/components/form/form.module';
import { PopupModule } from '../../core/components/popup/popup.module';
import { TableModule } from 'src/app/core/components/table/table.module';

@NgModule({
    declarations: [DownloadsComponent],
    providers: [DatePipe, CurrencyPipe],
    imports: [downloadsRouting, CommonModule, PopupModule, FormsModule, FormModule, TableModule],
})
export class DownloadsModule {}
