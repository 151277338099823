import { Component, OnInit } from '@angular/core';
import { IspvisionService, VisionWs } from '../../../core/services/ispvision.service';
import { TableService } from 'src/app/core/services/table.service';
import { TableColumn } from 'src/app/core/models/table.model';
import { Breakpoint } from 'src/app/core/services/responsive.service';
import { RowItem } from 'src/app/core/models/item.model';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.less'],
})
export class OrdersComponent implements OnInit {
    public orders: any[] = [];

    constructor(
        public visionWs: IspvisionService,
        public tableService: TableService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe
    ) {}

    async ngOnInit(): Promise<void> {
        this.orders = await this.visionWs.getOrders();

        this.tableService.register(
            {
                paginator: true,
                breakpoint: { column: [Breakpoint.MD], row: [Breakpoint.MD] },
            },
            this.mapColumns.bind(this),
            this.mapInvoicesToTable.bind(this),
            await this.visionWs.getOrders()
        );
    }

    private mapColumns(): TableColumn[] {
        return [
            {
                item: { type: 'text', text: 'Ordernummer' },
                sortOn: 'order_id',
                width: 10,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
            {
                item: { type: 'text', text: 'Orderdatum' },
                sortOn: 'created_at',
                width: 15,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
            {
                item: { type: 'text', text: 'Naam verkoper' },
                sortOn: 'name',
                width: 30,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 80,
                },
            },
            {
                item: { type: 'text', text: 'Referentie' },
                sortOn: 'reference',
                width: 25,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 80,
                },
            },

            {
                item: { type: 'text', text: 'Totaalprijs' },
                sortOn: 'netto_grand_total',
                width: 10,
                breakpoint: {
                    breakpoint: Breakpoint.MD,
                    widthUntil: 40,
                },
            },
        ];
    }

    /**
     * Map invoices to table format
     *
     * @author    Arjan Koemans <arjan@safira.nl>
     */
    private mapInvoicesToTable(order: {
        order_id: number;
        created_at: string;
        name: string;
        order: string;
        reference: string;
        netto_grand_total: number;
    }): RowItem {
        return {
            type: 'row',
            expandable: {
                breakpoint: Breakpoint.MD,
                colspan: 2,
                gap: 10,
                closedHeight: 16.8,
                openHeight: 83.6,
            },
            items: [
                {
                    type: 'text',
                    text: order.order_id,
                    expansion: 'hidden',
                    action: {
                        type: 'route',
                        route: '/orders/' + order.order_id,
                    },
                },
                {
                    type: 'text',
                    text: this.datePipe.transform(order.created_at, 'dd-MM-yyyy'),
                    expansion: 'hidden',
                    action: {
                        type: 'route',
                        route: '/orders/' + order.order_id,
                    },
                },
                {
                    type: 'text',
                    text: order.name,
                    expansion: 'hidden',
                    action: {
                        type: 'route',
                        route: '/orders/' + order.order_id,
                    },
                },
                {
                    type: 'text',
                    text: order.reference,
                    expansion: 'hidden',
                    action: {
                        type: 'route',
                        route: '/orders/' + order.order_id,
                    },
                },
                {
                    type: 'text',
                    text: this.currencyPipe.transform(order.netto_grand_total, 'EUR', 'symbol'),
                    expansion: 'hidden',
                    action: {
                        type: 'route',
                        route: '/orders/' + order.order_id,
                    },
                },
            ],
        };
    }
}
