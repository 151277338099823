import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './details/details.component';

export const ordersRoutes: Routes = [
    {
        path: 'orders',
        component: OrdersComponent,
    },
    {
        path: 'orders/:orderId',
        component: OrderDetailsComponent,
    },
];

export const ordersRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(ordersRoutes);
